import { Injectable } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SsApiService } from '../api/ss-api.service';
import { TimeService } from '../time.service';
import { UserBonusesService } from '../user/user-bonuses.service';
import { DEFAULT_TRANSLATES, ILootboxesTranslatesCMS } from './lootbox-translates';
import { TranslationService } from '../../shared/translation/translation.service';
import { StaticContentService } from '../static-content.service';
import { BonusStage, BonusType } from '../user/data/user-bonuses.data';
import { ArabicService } from '../arabic.service';

const LOOTBOXES_TRANSLATES_SLUG = 'lootboxes-translates';

@Injectable({
  providedIn: 'root',
})
export class LootboxService {
  /**
   * Current chosen lootbox prize
   */
  public currentLootboxPrize: any;

  /**
   * Player has issued loot box
   */
  private _playerHasIssuedLootBox: boolean;

  /**
   * Lootbox list
   */
  private _lootboxList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Loot box issued list
   */
  private _lootIssuedBoxList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Loot box isuued list for promo
   * @private
   */
  private _promoLootBoxList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Lootbox images list
   */
  private _boxList: { name: string, img: string }[] = [
    { name: 't.lootbox-1', img: '/assets/img/lootbox/LootBox Bonus 1.svg' },
    { name: 't.lootbox-2', img: '/assets/img/lootbox/LootBox Bonus 2.svg' },
    { name: 't.lootbox-3', img: '/assets/img/lootbox/LootBox Bonus 3.svg' },
    { name: 't.lootbox-4', img: '/assets/img/lootbox/LootBox Bonus 4.svg' },
    { name: 't.lootbox-5', img: '/assets/img/lootbox/LootBox Bonus 5.svg' },
    { name: 't.lootbox-6', img: '/assets/img/lootbox/LootBox Bonus 6.svg' },
    { name: 't.lootbox-7', img: '/assets/img/lootbox/LootBox Bonus 7.svg' },
    { name: 't.lootbox-8', img: '/assets/img/lootbox/LootBox Bonus 8.svg' },
    { name: 't.lootbox-9', img: '/assets/img/lootbox/LootBox Bonus 9.svg' },
    { name: 't.lootbox-10', img: '/assets/img/lootbox/LootBox Bonus 10.svg' },
  ];

  /**
   * Lootbox images list rtl
   */
  private _boxListRtl: { name: string, img: string }[] = [
    { name: 't.lootbox-1', img: '/assets/img/lootbox-rtl/1.png' },
    { name: 't.lootbox-2', img: '/assets/img/lootbox-rtl/2.png' },
    { name: 't.lootbox-3', img: '/assets/img/lootbox-rtl/3.png' },
    { name: 't.lootbox-4', img: '/assets/img/lootbox-rtl/4.png' },
    { name: 't.lootbox-5', img: '/assets/img/lootbox-rtl/5.png' },
    { name: 't.lootbox-6', img: '/assets/img/lootbox-rtl/6.png' },
    { name: 't.lootbox-7', img: '/assets/img/lootbox-rtl/7.png' },
    { name: 't.lootbox-8', img: '/assets/img/lootbox-rtl/8.png' },
    { name: 't.lootbox-9', img: '/assets/img/lootbox-rtl/9.png' },
    { name: 't.lootbox-10', img: '/assets/img/lootbox-rtl/10.png' },
  ];

  /**
   * Set true if lootboxes translates loaded from CMS
   * @private
   */
  private _lootboxesTranslatesLoaded: boolean = false;

  /**
   * Lootboxes translates Replay Subject
   * @private
   */
  public lootboxesTranslates$ = this._static.item({ slug: LOOTBOXES_TRANSLATES_SLUG }).pipe(
    filter(data => Boolean(data) && !this._lootboxesTranslatesLoaded),
    tap(data => this._lootboxesTranslatesData = data[0]),
    catchError(() => {
      this._lootboxesTranslatesLoaded = true;
      this._lootboxesTranslatesData = {
        FieldModifyTranslations: null,
        Translates: {},
      };
      return of({});
    }),
    shareReplay(1),
  );

  /**
   * Save lootboxes translates data for use in future
   * @private
   */
  private _lootboxesTranslatesData: ILootboxesTranslatesCMS;

  /**
   * True if is today activated promo lootbox
   * @private
   */
  private _isActivatedTodayPromoLootbox: boolean;

  constructor(
    private _ssApi: SsApiService,
    private _time: TimeService,
    private _bonuses: UserBonusesService,
    private _translate: TranslationService,
    private _static: StaticContentService,
    private _rtl: ArabicService,
  ) {
  }

  get playerHasIssuedLootBox(): boolean {
    return this._playerHasIssuedLootBox;
  }

  get lootIssuedBoxList$(): ReplaySubject<any> {
    return this._lootIssuedBoxList$;
  }

  get lootBoxList$(): ReplaySubject<any> {
    return this._lootboxList$;
  }

  get lootboxImagesList() {
    return this._rtl ? this._boxListRtl : this._boxList;
  }

  get promoLootBoxList$(): ReplaySubject<any> {
    return this._promoLootBoxList$;
  }

  get isActivatedTodayPromoLootbox(): boolean {
    return this._isActivatedTodayPromoLootbox;
  }

  /**
   * Load user loot box
   */
  public loadUserLootBox() {
    return this.lootboxesTranslates$.pipe(
      switchMap(() => {
        return this._ssApi.playerLootbox().pipe(
          tap((e: any[]) => {
            this.resolveIssuedLootBox(e);
          }),
          map(list => list.map(e => {
            // e.isWelcome = this._bonuses.isWelcomeBonus(e.title);
            e.lootboxTitle = e?.title;
            e.type = BonusType.LOOTBOX;
            e.cancelable = e?.stage !== BonusStage.EXPIRED;
            e.activatable = true;
            e.created_at = e?.created_at ? new Date(e?.created_at) : null;
            return e;
          })),
        );
      }),
    );
  }

  /**
   * Resolve loot box list
   * @param list
   */
  public resolveIssuedLootBox(list: any[]) {
    if (list.length) {
      const issuedLootBox = list.filter(l => l.stage === 'issued');
      list.forEach((elem) => elem.type = 'lootbox');
      this._lootboxList$.next(list.map(e => this.resolveLootboxTranslates(e)));
      issuedLootBox.map((e) => e.strategy = 'lootbox_item');
      this._lootIssuedBoxList$.next(issuedLootBox.map(e => this.resolveLootboxTranslates(e)));
      this._promoLootBoxList$.next(issuedLootBox.filter(e => e.title.includes('landing')).map(e => this.resolveLootboxTranslates(e)));
      this._isActivatedTodayPromoLootbox = list.some(l => l.stage === 'activated' && l.title.includes('landing') && this._time.isTodayDate(new Date(l.valid_until)));
      this._playerHasIssuedLootBox = !!issuedLootBox.length;
      this._boxList = this._boxList.slice(0, issuedLootBox[0] && issuedLootBox[0].items.length);
      this._boxListRtl = this._boxListRtl.slice(0, issuedLootBox[0] && issuedLootBox[0].items.length);
    } else {
      this._playerHasIssuedLootBox = false;
      this._lootIssuedBoxList$.next([]);
      this._promoLootBoxList$.next([]);
    }
  }

  /**
   * Activate loot box by id
   * @param id
   */
  public activateLootBox(id) {
    return this._ssApi.playerLootboxActivate(id);
  }

  /**
   * Cancel lootbox
   * @param id
   */
  public cancelLootbox(id) {
    return this._ssApi.playerLootboxDelete(id);
  }

  /**
   * Resolve lootbox translates for use in template
   * Find lootbox by field (FieldModifyTranslations) from CMS and add translates
   * @param lootbox
   * @private
   */
  public resolveLootboxTranslates(lootbox: any) {
    const translatesArr = Object.entries(this._lootboxesTranslatesData && this._lootboxesTranslatesData.Translates || {});

    if (translatesArr.length && this._lootboxesTranslatesData.FieldModifyTranslations) {
      const field = this._lootboxesTranslatesData.FieldModifyTranslations.trim();

      const translates = translatesArr.find(tr => {
        if (tr[0].includes(',')) {
          return tr[0]
            .trim()
            .toLocaleLowerCase()
            .split(',')
            .map(t => t.trim())
            .some(t => String(this._bonuses.mapBonusTitle(lootbox[field]).trim().toLocaleLowerCase() === t.trim().toLocaleLowerCase()));
        }

        return String(lootbox[field]).trim().toLocaleLowerCase() === tr[0].trim().toLocaleLowerCase();
      });

      if (translates) {
        lootbox = { ...lootbox, ...translates[1] };
      } else {
        lootbox = { ...lootbox, ...DEFAULT_TRANSLATES };
      }
    } else {
      lootbox = { ...lootbox, ...DEFAULT_TRANSLATES };
    }

    return lootbox;
  }
}
