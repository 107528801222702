import { Injectable } from '@angular/core';
import { ScriptLoaderService } from '../script-loader.service';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { UserService } from '../user/user.service';
import { LanguageService } from '../language/language.service';
import { PlatformService } from '../platform.service';
import { CookieService } from 'ngx-unificator/services';
import { StaticContentService } from '../static-content.service';
import { ZENDESK_SCRIPT_URL } from './zendesk-chat-data';
import { environment } from '../../../../environments/environment';
import { ServiceWorkerService } from '../service-worker.service';
import { unreadMessagesCount } from '../../helpers/signals';

declare var zE;

@Injectable({
  providedIn: 'root'
})

export class ZendeskChatService {

  /**
   * Returns true if zendesk chat available
   */
  private _available: boolean;

  /**
   * Emits true if zendesk chat available
   */
  private _available$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /**
   * Returns true if chat open
   * @private
   */
  private _isOpen: boolean;

  constructor(
    private _scriptLoader: ScriptLoaderService,
    private _user: UserService,
    private _lang: LanguageService,
    private _platform: PlatformService,
    private _cookie: CookieService,
    private _static: StaticContentService,
    private _pwa: ServiceWorkerService,
  ) {
  }

  /**
   * Success from outside
   */
  get available() {
    return this._available;
  }

  get available$() {
    return this._available$;
  }

  get isOpen() {
    return this._isOpen;
  }

  /**
   * Get widget api key
   * @private
   */
  private _getWidgetApiKey$() {
    return this._static.item({slug: 'zendesk-api-key'}).pipe(
      filter(data => !!data && data[0]),
      map(data => data[0].Content && data[0].Content.trim())
    );
  }

  /**
   * Init zendesk chat
   */
  public initChat(forVip: boolean = false) {
    if (this._platform.isBrowser) {
      this._user.auth$.pipe(
        switchMap(() => this._getWidgetApiKey$()),
        filter(key => !!key),
        tap(() => {
          const zeScript = document.querySelector('#ze-snippet');
          if (zeScript) {
            zeScript.remove();
          }
          this._available = false;
          this._available$.next(false);
        }),
        delay(0),
        switchMap(key => this._scriptLoader.load(`${ZENDESK_SCRIPT_URL}?key=${key}&timestamp=${Date.now()}`, undefined, {id: 'ze-snippet'})),
        filter(() => Boolean(zE)),
        tap(() => {
          this._available = true;
          this._available$.next(true);
          this._setInitFunctionalData(forVip);
          zE('messenger', 'close');
        }),
        catchError(error => {
          this._available = false;
          this._available$.next(false);
          return of(error);
        })
      ).subscribe();
    }
  }

  /**
   * Set init data for functional chat
   * @private
   */
  private _setInitFunctionalData(isVip: boolean = false) {
    this.hide();

    if (this._user.auth) {
      this.setUserData();
    }
    this._onClose();
    this._onChangeLanguage();
    this._onMessage();
    this._addTags([this._lang.current ? this._lang.current : '',
      this._cookie.get('id_id') ? this._cookie.get('id_id') : '']);
  }

  /**
   * Toggle chat
   * @open
   */
  public toggle() {
    this.isOpen ? this.hide() : this.open();
  }

  /**
   * Hide chat
   */
  public hide() {
    zE('messenger', 'close');
  }

  /**
   * Open chat
   */
  public open() {
    zE('messenger', 'open');
  }

  /**
   * Handler on close widget
   * @private
   */
  private _onClose() {
    if (this._platform.isBrowser) {
      zE('messenger:on', 'close', () => {
        this._isOpen = false;
        this._removeUnreadDotFromFavicon();
      });
    }
  }

  /**
   * Set locale for chat
   * @private
   * @locale
   */
  private _setLocale(locale) {
    if (this.available) {
      zE('messenger:set', 'locale', locale);
    }
  }

  /**
   * Add tags
   * @private
   * @tags
   */
  private _addTags(tags: string[]) {
    zE('messenger:set', 'conversationTags', tags);
  }

  /**
   * Change locale for chat
   * @private
   */
  private _onChangeLanguage() {
    this._lang.langChange$.pipe(
      tap(locale => this._setLocale(locale))
    ).subscribe();
  }

  /**
   * Open chat if message coming
   * @private
   */
  private _onMessage() {
    if (this._platform.isBrowser && this.available && zE) {
      zE('messenger:on', 'unreadMessages', countMessage => {
        unreadMessagesCount.set(countMessage);
        if (!this._isOpen && countMessage > 0) {
          this._pwa.stopGifAnimation();
          this.open();
          this._addUnreadDotToFavicon();
        } else {
          this._removeUnreadDotFromFavicon();
        }
      });
    }
  }

  private _updateFavicon(favicon: HTMLLinkElement, newFaviconUrl: string) {
    favicon.href = newFaviconUrl;
  }
  private _addUnreadDotToFavicon() {
    if (this._platform.isBrowser) {
      const favicons = this._getFavicons();

      favicons.forEach((favicon) => {
        const originalFavicon = favicon.href?.includes('assets') ? favicon.href : null;
        if (!originalFavicon) return;

        favicon.dataset.originalHref = originalFavicon;

        const canvas = document.createElement('canvas');
        const img = new Image();
        img.src = originalFavicon;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;

          const context = canvas.getContext('2d');
          if (context) {
            context.drawImage(img, 0, 0);

            // Draw the red dot
            const dotSize = Math.max(6, img.width / 6); // Adjust dot size dynamically
            const xPos = canvas.width - dotSize;
            const yPos = dotSize;

            context.beginPath();
            context.arc(xPos, yPos, dotSize, 0, 2 * Math.PI);
            context.fillStyle = 'red';
            context.fill();

            this._updateFavicon(favicon, canvas.toDataURL('image/png'));
          }
        };
      });
    }
  }

  private _removeUnreadDotFromFavicon() {
    const favicons = this._getFavicons();
    favicons.forEach((favicon) => {
      const originalHref = favicon.dataset.originalHref;
      if (originalHref) {
        favicon.href = originalHref;
      }
    });
  }

  private _getFavicons(): NodeListOf<HTMLLinkElement> {
    return document.querySelectorAll('link[rel*="icon"]');
  }

  /**
   * Set user data (name, email)
   * @param data
   * @private
   */
  public setUserData() {
    const userObj = {
      user_id: `${this._user.info.id}`,
      first_name: this._user.info.first_name,
      last_name: this._user.info.last_name,
      date_of_birth: this._user.info.date_of_birth,
      email: this._user.info.email
    };
    if (Object.values(userObj).every((e) => !!e)) {
      zE('messenger', 'loginUser', (callback) => {
        fetch(`${environment.zendesk_host}/auth`, {method: 'POST', body: JSON.stringify(userObj)}).then((res) => {
          res.json().then((jwt) => {
            callback(jwt.token);
          });
        });
      });
    }
  }

}

