import { ReplaySubject } from 'rxjs';

type ValuesOf<T> = T[keyof T];

export type ABTestTaskListType = keyof typeof abTestList;
export type HeaderMainGroupType = ValuesOf<{
  [K in keyof typeof abTestList]: typeof abTestList[K]['headerMainGroup'];
}>;
export type ABVariantsType = ValuesOf<{
  [K in keyof typeof abTestList]: ValuesOf<typeof abTestList[K]['abTestGroupIds']>;
}>;

export interface ABTestItem<K extends keyof typeof abTestList> {
  nameOfTask?: ABTestTaskListType;
  alias?: string;
  headerMainGroup: HeaderMainGroupType;
  abTestVariants: ABVariantsType[];
  abTestGroupIds: Record<keyof typeof abTestList[K]['abTestGroupIds'], ABVariantsType>;
  resolvedValue?: ABVariantsType;
  resolvedValue$?: ReplaySubject<ABVariantsType>;
  resolvedCookieValue?: ABVariantsType;
  syncOnAuth?: boolean;
  debug?: boolean;
}

export const AB_TEST_LIST: {
  [K in keyof typeof abTestList]: ABTestItem<K>;
} = {} as any;

export const abTestList = {
  'DEVSS5127': {
    alias: 'DEVSS5127',
    headerMainGroup: 'ID686',
    abTestGroupIds: {
      V1: 'ID686',
      V2: 'ID687',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS5298': {
    alias: 'DEVSS5298',
    headerMainGroup: 'ID646',
    abTestGroupIds: {
      V1: 'ID646',
      V2: 'ID647',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS5602': {
    alias: 'DEVSS5602',
    headerMainGroup: 'ID807',
    abTestGroupIds: {
      V1: 'ID806',
      V2: 'ID807',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS5757': {
    alias: 'DEVSS5757',
    headerMainGroup: 'ID815',
    abTestGroupIds: {
      V1: 'ID815',
      V2: 'ID816',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS5940': {
    alias: 'DEVSS5940',
    headerMainGroup: 'ID843',
    abTestGroupIds: {
      V1: 'ID843',
      V2: 'ID844',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS6305': {
    alias: 'DEVSS6305',
    headerMainGroup: 'ID922',
    abTestGroupIds: {
      V1: 'ID922',
      V2: 'ID923',
    },
    syncOnAuth: true,
    debug: false,
  },
  'DEVSS6504': {
    alias: 'DEVSS6504',
    headerMainGroup: 'ID967',
    abTestGroupIds: {
      V1: 'ID967',
      V2: 'ID968',
    },
    syncOnAuth: true,
    debug: false,
  },
} as const;
