import { Validators } from '@angular/forms';
import { ValidationPatterns } from '../../../../../../core/helpers/validation-patterns';
import {
  CreditCardType,
  CreditCardTypeCardBrandName,
  CreditCardTypeService,
} from '../../../../../../core/services/credit-card-type.service';
import { CustomValidators } from '../../../../../../core/helpers/custom-validators';
import { Payment } from 'payments-lib-types';
import { UserInfoService } from '../../../../../../core/services/user/user-info.service';
import { DecoratorService } from '../../../../../../core/helpers/decorator-service.service';
import { UserFieldType } from '../../../../../../core/helpers/user-fields.data';

export interface ICustomFieldsConfig {
  label?: string;
  topDescription?: string;
  bottomDescription?: string;
  brand?: string[];
  currencies?: string[];
  action?: Payment.Action;
  countries?: string[];
  mask?: string;
  uppercase?: boolean;
}

export interface IFieldsConfig {
  [key: string]: {
    label?: string;
    profile_label?: string;
    type?: string,
    hint?: string;
    validator?: any[];
    autocomplete?: string;
    mask?: string;
    maxLength?: number | string;
    label_by_options?: ICustomFieldsConfig[];
    description_by_options?: ICustomFieldsConfig[];
    uppercase?: boolean;
    getValidatorPattern?: (type: CreditCardTypeCardBrandName) => {};
    switchLength?: number;
    placeholder?: string;
    topDescription?: string;
    bottomDescription?: string;
  };
}

/**
 * Use for get credit card pattern
 */
const creditCardService = new CreditCardTypeService();

const userInfo = DecoratorService?.injector?.get(UserInfoService);

export const FieldsConfig: IFieldsConfig = {
  number: {
    label: 'labl.ccn',
    autocomplete: 'cc-number',
    getValidatorPattern: (type: CreditCardTypeCardBrandName): Validators[] => {
      return type === CreditCardType.VISA || type === CreditCardType.MASTERCARD
        ? [CustomValidators.validateVisaOrMastercard(type, creditCardService.getCardPattern(type))]
        : [Validators.pattern(ValidationPatterns.creditCard)];
    },
    validator: [Validators.pattern(ValidationPatterns.creditCard)],
    mask: '0000 0000 0000 0000'
  },
  customer_name: {
    label: 'labl.customer-name',
    description_by_options: [
      {
        topDescription: 't.customer-name-tip',
      }
    ]
  },
  bank_id: {
    label: 'labl.bankName'
  },
  amount: {
    label: 'labl.amount'
  },
  secureId: {
    label: 'labl.secure-id',
  },
  name: {
    label: 'labl.name'
  },
  account: {
    label: 'labl.acc'
  },
  phone: {
    label: 'labl.phone',
    validator: [Validators.required],
    mask: '+0{15}'
  },
  phoneNumber: {
    label: 'labl.phone',
    validator: [Validators.required],
    mask: '+0{15}'
  },
  tel: {
    label: 'labl.phone',
    validator: [Validators.required],
    mask: '+0{15}'
  },
  street1: {
    label: 'labl.street'
  },
  street2: {
    label: 'labl.street'
  },
  city: {
    label: 'labl.city',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.onlyLettersAndSpacesAndHyphenBracesPoint),
      Validators.maxLength(255)
    ],
  },
  postal_code: {
    label: 'labl.zip',
    profile_label: 'labl.postal-code',
    validator: userInfo?.isCA
      ? [Validators.required, Validators.pattern(ValidationPatterns.canadianPostalCode)]
      : [Validators.required, Validators.pattern(ValidationPatterns.lettersAndNumbersWithSpacesAndSomeSymbols), Validators.maxLength(50)]
  },
  state: {
    label: 'labl.state'
  },
  nationalId: {
    label: 'labl.nationalId',
  },
  RegCountry: {
    label: 'labl.country'
  },
  RegName: {
    label: 'labl.name'
  },
  account_type: {
    label: 'labl.acc-type',
    type: 'select'
  },
  account_identifier: {
    label: 'labl.acc-ind',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    uppercase: true,
    label_by_options: [
      {
        label: 'labl.iban',
        brand: ['bank_transfer'],
        currencies: ['EUR', 'NOK'],
        action: Payment.Action.Cashout
      }
    ]
  },
  swift: {
    label: 'labl.swift',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    uppercase: true
  },
  bank_name: {
    label: 'labl.bank-name'
  },
  bank_address: {
    label: 'labl.bank-address'
  },
  account_owner_name: {
    label: 'labl.acc-ow-name',
    description_by_options: [
      {
        topDescription: 't.account-owner-tip',
      }
    ]
  },
  customer_purse: {
    label: 'labl.acc-num'
  },
  holder: {
    label: 'labl.cch',
    type: 'text',
    autocomplete: 'cc-name',
  },
  tokenize: {
    label: 'labl.tokenize',
  },
  cvv: {
    label: 'labl.cvv',
    type: 'text',
    autocomplete: 'cc-csc',
    validator: [Validators.pattern(ValidationPatterns.cvv)],
    maxLength: 4
  },
  seamless_expiry_date: {
    label: ''
  },
  encCreditcardNumber: {
    label: 'labl.ccn',
    autocomplete: 'cc-number',
    getValidatorPattern: (type: CreditCardTypeCardBrandName): Validators[] => {
      return type === CreditCardType.VISA || type === CreditCardType.MASTERCARD
        ? [CustomValidators.validateVisaOrMastercard(type, creditCardService.getCardPattern(type))]
        : [Validators.pattern(ValidationPatterns.creditCard)];
    },
    validator: [Validators.pattern(ValidationPatterns.creditCard)],
    mask: '0000 0000 0000 0000'
  },
  pan: {
    label: 'labl.ccn',
    autocomplete: 'cc-number',
    validator: [Validators.pattern(ValidationPatterns.creditCard)]
  },
  cardHolder: {
    label: 'labl.cch',
    type: 'text',
    autocomplete: 'cc-name',
  },
  clearingNumber: {
    label: 'labl.bsb-number',
    type: 'text',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
  },
  encCvv: {
    label: 'labl.cvv',
    type: 'text',
    autocomplete: 'cc-csc',
    validator: [Validators.pattern(ValidationPatterns.cvv)],
    maxLength: 4
  },
  expiry_date: {
    label: ''
  },
  expiryMonth: {
    label: 'labl.mm',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
      Validators.maxLength(2),
      Validators.minLength(2),
      Validators.max(12),
      Validators.min(1)
    ],
    switchLength: 2,
  },
  month: {
    label: 'labl.mm',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
      Validators.maxLength(2),
      Validators.minLength(2),
      Validators.max(12),
      Validators.min(1)
    ],
    switchLength: 2,
  },
  expiryYear: {
    label: 'labl.yy',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
      Validators.maxLength(2),
      Validators.minLength(1)
    ],
    switchLength: 2,
  },
  year: {
    label: 'labl.yy',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
      Validators.maxLength(2),
      Validators.minLength(1)
    ],
    switchLength: 2,
  },
  email: {
    label: 'labl.email',
    validator: [Validators.pattern(ValidationPatterns.email)]
  },
  account_from_form : {
    label: 'labl.email',
    validator: [Validators.pattern(ValidationPatterns.email)]
  },
  NeoSurfEmail: {
    label: 'labl.email',
    validator: [Validators.pattern(ValidationPatterns.email)]
  },
  address: {
    label: 'labl.crypto-address',
    profile_label: 't.Address',
    maxLength: '40'
  },
  password: {
    label: 'labl.password'
  },
  username: {
    label: 'labl.username'
  },
  bankName: {
    label: 'labl.bankName'
  },
  branchAddress: {
    label: 'labl.branchAddress'
  },
  bic: {
    label: 'labl.bic',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    uppercase: true
  },
  accountNumber: {
    label: 'labl.accountNumber',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    uppercase: true,
    label_by_options: [
      {
        label: 'labl.accountNumber',
        brand: ['inpay'],
        action: Payment.Action.Cashout,
        currencies: ['NZD', 'CAD', 'AUD']
      },
      {
        label: 'labl.iban',
        brand: ['inpay'],
        action: Payment.Action.Cashout,
      },
    ],
    description_by_options: [
      {
        currencies: ['CAD'],
        action: Payment.Action.Cashout,
        brand: ['inpay'],
        topDescription: 't.account-number-tip',
      },
      {
        topDescription: 't.account-number-tip-nz',
        brand: ['inpay'],
        action: Payment.Action.Cashout,
        currencies: ['NZD']
      }
    ]
  },
  beneficiaryName: {
    label: 'labl.beneficiaryName',
    description_by_options: [
      {
        topDescription: 't.beneficiary-name-tip',
      }
    ]
  },
  document_id_cpf: {
    label: 'labl.documentId',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
      Validators.minLength(11),
    ],
    type: 'number',
    mask: '00000000000'
  },
  bank_branch: {
    label: 'labl.branch',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
    ],
    type: 'number',
    mask: '00000',
    maxLength: 5,
  },
  bank_code: {
    label: 'labl.bankCode',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.numbers),
    ],
    type: 'number',
    mask: '00000',
    maxLength: 5,
  },
  bank_account: {
    label: 'labl.bankAccount',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.lettersAndNumbers),
    ],
  },
  countryCode: {
    type: 'select',
    label: 'labl.country-code',
    validator: [Validators.pattern(ValidationPatterns.twoLetters)],
    mask: 'AA',
    uppercase: true,
  },
  iban: {
    label: 'labl.iban',
    mask: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
    uppercase: true,
  },
  destinationAccount: {
    label: 'labl.email'
  },
  bankAccountName: {
    label_by_options: [
      {
        label: 'labl.beneficiaryName',
        brand: ['noda~noda'],
        action: Payment.Action.Cashout,
        currencies: ['EUR']
      }
    ]
  },
  bankIBAN: {
    mask: 'A{100}0{100}',
    validator: [Validators.pattern(ValidationPatterns.noSpacesAndSymbols)],
    uppercase: true,
    label_by_options: [
      {
        label: 'labl.iban',
        brand: ['noda~noda'],
        action: Payment.Action.Cashout,
        currencies: ['EUR']
      }
    ]
  },
  first_name: {
    label: 'labl.first-name',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.letters),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
    ],
  },
  last_name: {
    label: 'labl.last-name',
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.letters),
      Validators.pattern(ValidationPatterns.noSpaceAtStart),
    ],
  },
  date_of_birth: {
    label: 'labl.date-b',
    type: UserFieldType.DATE,
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.dateOfBirth),
      CustomValidators.eighteenYearsOld],
  },
  wallet_id: {
    label: 'labl.wallet-id'
  },
  wallet_pwd: {
    label: 'labl.wallet-pwd'
  },
  full_name: {
    label: 'labl.full-name'
  },
  accountPhoneCountryCode: {
    label: 'labl.phone-country-code'
  },
  accountPhoneNumber: {
    label: 'labl.phone-number',
  }
};


/**
 * Custom data for some field, if need change for some method or currency
 */
export const CustomLabelForSomeMethod = {
  JPY: {
    inpay: {
      frontend_label: 'labl.zengin-code',
      maxLength: 7,
      belowHint: 'hint.zengin-code'
    }
  }
};
