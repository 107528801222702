import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { PlatformService } from './platform.service';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from './environment.service';
import { GlobalEventsService } from './global-events.service';
import { StaticPageService } from 'src/app/page/static-page/static-page.service';
import { ReplaySubject } from 'rxjs';
import { distinct, filter, map, pairwise, tap } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
import { WrSocketService } from './wr-socket.service';
import { isNullOrUndefined } from '../helpers/utils';
import { LanguageService } from './language/language.service';
import { WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private _localesForHeader = ['en', 'fi', 'de', 'no', 'en-ca', 'en-nz', 'pl', 'pt', 'cz', 'fr', 'ja', 'en-au', 'it', 'ar'];

  private _previousUrl: string;

  public originalLocationUrl: string;

  constructor(
    private _meta: Meta,
    private _title: Title,
    private _platform: PlatformService,
    private _location: Location,
    private _env: EnvironmentService,
    private _globalEvents: GlobalEventsService,
    private _page: StaticPageService,
    private _router: Router,
    private _wrSocket: WrSocketService,
    private _lang: LanguageService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window,
  ) {
  }


  /**
   * Set in head Title & description
   * @param tags
   */
  public setMetaTags(tags: any) {
    this._title.setTitle(tags.MetaTitle || 'Spin Samurai');
    this._meta.updateTag({name: 'description', content: tags.MetaDesc || ''});

    if ('NoIndex' in tags && tags.NoIndex) {
      this.addMetaRobotNoIndex();
    }
  }

  /**
   * Add new alternate/canonical links and remove old links
   */
  public updateGlobalHeadLink() {
    this._router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      ).subscribe((events: RoutesRecognized[]) => {
      this._previousUrl = events[0].urlAfterRedirects;
    });
    this._globalEvents.routerNavigationEnd$.pipe(
      distinct(),
      tap(() => this._wrSocket.sendEventPageOpen()),
    ).subscribe((e) => {
      this._removeOldLinks(['alternate', 'canonical', 'x-default']);
      this._addHeadLinkAlternate();
      this._addHeadLinkCanonicalAndXDefault('canonical');
      this._addHeadLinkCanonicalAndXDefault('x-default');
      this._addLangTagForHTML();
      this._check403Page(e);
      if (this._platform.isBrowser) {
        this.originalLocationUrl = this._window.location.origin;
      }
    });
  }

  /**
   * Add link alternate <link rel="alternate" href="https://www.spinsamurai.com/en" hreflang="en">
   */
  private _addHeadLinkAlternate() {
    this._localesForHeader.forEach(e => {
      const link: HTMLLinkElement = this._document.createElement('link');
      let url: any = this._location.path().split('/');
      url[1] = e;
      url = url.join('/');
      const urlWithoutLocale = url.split('/').slice(2).join('/');
      link.setAttribute('rel', 'alternate');
      if (e !== 'en') {

        link.setAttribute('href', environment.app_host + url);
        link.setAttribute('hreflang', e);

        if (e === 'en-au') {
          link.setAttribute('href', environment.mirror_host_au + '/en-au' + `${urlWithoutLocale.length ? '/' : ''}${ urlWithoutLocale }`);
          link.setAttribute('hreflang', 'en-au');
        }

        if (e === 'cz') {
          link.setAttribute('href', environment.mirror_host_cz);
          link.setAttribute('hreflang', 'cz');
        }

      } else {
        link.setAttribute('href', environment.app_host + `/${ urlWithoutLocale }`);
        link.setAttribute('hreflang', e);
      }
      this._document.head.appendChild(link);
    });
  }

  /**
   * Add canonical link <link rel="canonical" href="/en">
   */
  private _addHeadLinkCanonicalAndXDefault(attr: string) {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', attr);
    switch (true) {
      case attr === 'canonical' && this._location.path().includes('en-au'):
        link.setAttribute('href', environment.mirror_host_au + this._location.path().replace('/en-au', '').split('?')[0]);
        break;
      case this._location.path() === '/en':
        link.setAttribute('href', environment.app_host + this._location.path().replace('/en', '').split('?')[0]);
        break;
      case this._location.path().includes('/en/'):
        link.setAttribute('href', environment.app_host + '/' + this._location.path().replace('/en/', '').split('?')[0]);
        break;
      case ['en-nz', 'en-ca', 'en-au'].some(item => this._location.path().includes(item)):
        link.setAttribute('href', environment.app_host + this._location.path().replace('/en/', '').split('?')[0]);
        break;
      case !this._location.path():
        link.setAttribute('href', environment.app_host);
        break;
      case this._location.path() === '/404' && !isNullOrUndefined(this._previousUrl):
        link.setAttribute('href', environment.app_host + this._previousUrl);
        break;
      case attr === 'x-default':
        link.setAttribute('href', environment.app_host + this._location.path().split('?')[0].replace(/\/[a-z]{2}(-[a-z]{2})?(?=\/|$)/, ''));
        break;
      default:
        link.setAttribute('href', environment.app_host + this._location.path().split('?')[0]);
        break;
    }
    this._document.head.appendChild(link);
  }

  /**
   * Remove old alternate\canonical links from DOM
   * @param typeOfLinks
   */
  private _removeOldLinks(typeOfLinks: any[]) {
    if (this._platform.isBrowser) {
      typeOfLinks.forEach(e => {
        const oldLinks: NodeListOf<HTMLLinkElement> = this._document.querySelectorAll(`link[rel="${ e }"]`);
        if (oldLinks.length) {
          oldLinks.forEach(j => {
            j.remove();
          });
        }
      });
    }
  }

  /**
   * Add in head <meta name="robots" content="noindex" />
   */
  public addMetaRobotNoIndex() {
    this._meta.addTags([
      {name: 'robot', content: 'noindex'}
    ]);
  }

  /**
   * Part for <html lang="en">
   * if lang == de -> lang="de"
   * if lang == en-ca -> lang="en"
   */
  private _addLangTagForHTML() {
    const defaultLocale = 'en';
    const matches = this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) ? this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) : this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)/);
    this._document.documentElement.lang = (matches && this._env.env.languageList.map(e => e.short).indexOf(matches[1]) !== -1) ? matches[1] : (this._lang.current || defaultLocale);
  }

  /**
   * get SeoContent from CMS for page
   */
  public getSeoContentForPage(slug: string): ReplaySubject<any> {
    return this._page.item({slug}).pipe(
      filter(data => !!data),
      map(page => page[0].SeoContent || page[0].Content),
    ) as ReplaySubject<any>;
  }

  /**
   * Check 403 and remove canonical and x-default
   * @param e
   * @private
   */
  private _check403Page(e) {
    if (e.url && e.url.includes('403')) {
      this._removeOldLinks(['canonical', 'x-default', 'alternate']);
    }
  }
}
