import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user/user.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { TranslationService } from '../shared/translation/translation.service';
import { LanguageService } from './language/language.service';
import { ArrayService } from './array.service';
import { OneSignalService } from './one-signal.service';
import { AbTestNewService } from '../ab-test/ab-test.service';

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService  {

  /**
   * List of observables that need to be preloaded before app start
   */
  private _dataToPreload: Array<Observable<any>> = [
    this._user.auth$.pipe(first()),
    this._translate.getUserTranslates$().pipe(first()),
    this._abTestNew.loadABTest$('DEVSS5602').pipe(first()),
    this._abTestNew.loadABTest$('DEVSS5940').pipe(first()),
];

  /**
   * Is data loaded
   */
  public loaded: boolean;

  constructor(
    public env: EnvironmentService,
    private _user: UserService,
    private _translate: TranslationService,
    private _language: LanguageService,
    private _array: ArrayService,
    private _abTestNew: AbTestNewService,
    private _oneSignal: OneSignalService
  ) { }

  /**
   * For main route resolving
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.env.env$.pipe(
      first(),
      tap(() => {
        if (!this._language.current) {
          const params = this._array.routeToArray(state.url);
          const routeLang = params[0];

          this._language.changeLang(this._language.detectUserLang(routeLang, this.env.env.locale.short, this.env.env.languageList));
        }
      }),
      switchMap(() => forkJoin(this._dataToPreload)),
      tap(() => {
        this.loaded = true;
        this._oneSignal.handleId();
      }));
  }
}
