@if (method && action && form) {
  <div [formGroup]="form">
    @if (!payment.isSecondDepositStep) {
      <div #amountEl>
        @if (form.get('amount')) {
          <app-amount-selector
            [control]="form.get('amount')"
            [valueList]="sumList"
            [min]="+method?.termsOfService?.restrictions?.minAmountValue"
            [max]="+method?.termsOfService?.restrictions?.maxAmountValue"
            (amountValue)="getAmountValue($event)"
          >
          </app-amount-selector>
        }
      </div>
      @if ((converter?.rate === converter.notAvailable || !converter?.rate) && method?.type === 'crypto' && action === 'deposit') {
        <div class="currency-selector-container">
          <div class="currency-selector-container__title">
            {{ 'btn.deposit' | translate }} <span>{{ 't.in' | translate }}</span>
          </div>
          <div class="currency-selector-container__selector">
            <app-currency-selector-standalone (changeCurrency)="changeAccountCurrency($event)"></app-currency-selector-standalone>
          </div>
        </div>
      }
      @if (
        (method && method?.type !== Payment.MethodType.Crypto) ||
        (method.type === Payment.MethodType.Crypto && action === Payment.Action.Cashout)
        ) {
        <div class="payment-info">
          @if (method?.termsOfService?.processingTime?.type !== 'none') {
            <span>{{ method?.termsOfService?.processingTime | processingTime }}</span>
          }
          @if (method?.termsOfService?.processingTime?.type !== 'none' && method?.termsOfService?.commissions) {
            ,
          }
          @if (method?.termsOfService?.commissions) {
            <span
            >{{ 't.fee' | translate }}
              {{
                method?.termsOfService?.commissions?.type !== 'none'
                  ? user.currentCurrency?.symbol + ' ' + method?.termsOfService?.commissions?.cents
                  : ''
              }}</span
            >
          }
          @if (method?.termsOfService?.restrictions?.minAmountValue || method?.termsOfService?.restrictions?.maxAmountValue) {
            ,
          }
          @if (method?.termsOfService?.restrictions?.minAmountValue) {
            <span
            >{{ 't.min' | translate }} {{ +method?.termsOfService?.restrictions?.minAmountValue }} {{ user.currentCurrency?.symbol }}</span
            >
          }
          @if (method?.termsOfService?.restrictions?.maxAmountValue) {
            ,
          }
          @if (method?.termsOfService?.restrictions?.maxAmountValue) {
            <span
            >{{ 't.max' | translate }} {{ +method?.termsOfService?.restrictions?.maxAmountValue }} {{ user.currentCurrency?.symbol }}</span
            >
          }
        </div>
      }
      <!-- Tip info for bank transfer cashout -->
      @if (method.brand === 'banklocal') {
        <div class="tip">
          {{ 't.bank-tip' | translate }}
        </div>
      }
      @switch (true) {
        @case (method?.type === Payment.MethodType.Crypto) {
          <ng-container *ngTemplateOutlet="cryptoFields"></ng-container>
        }
        @default {
          <ng-container *ngTemplateOutlet="baseFields"></ng-container>
        }
      }
      <ng-template #baseFields>
        <div class="field-list">
          @for (field of fieldsToRender; track field) {
            @switch (true) {
              <!--BANNER-->
              @case (field.type === Form.MethodFieldType.Banner) {
                <div class="input-container html" [innerHTML]="field.htmlContent | safe: 'html'"></div>
              }
              <!--SELECT-->
              @case (field.type === FieldType.SELECT) {
                <app-form-input
                  class="input-container"
                  [control]="form.get(field.fieldName)"
                  [attr.data-field-name]="field.fieldName"
                  [hidden]="hideBankTransferSelect && form.get(field.fieldName).value"
                >
                  <app-select class="select" [formControlName]="field.fieldName">
                    <app-select-header class="select__header"
                    >{{ (Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate }}
                    </app-select-header>
                    @if (field?.fieldName !== 'country_code' && field?.fieldName !== 'countryCode') {
                      <app-select-dropdown class="select__dropdown">
                        @for (option of field?.options; track option) {
                          <app-select-option class="select__option" [value]="option.value">{{ option.label }} </app-select-option>
                        }
                      </app-select-dropdown>
                    }
                  </app-select>
                </app-form-input>
              }
              <!--SELECT-->
                <!-- Mobile -->
              @case (field.type === 'tel') {
                <app-form-input class="input-container" [control]="form.get(field.fieldName)">
                  <app-phone-form [formControlName]="field.fieldName"></app-phone-form>
                </app-form-input>
              }
              <!-- Mobile -->
                <!-- CASCADE SELECT-->
              @case (field.type === Form.MethodFieldType.CascadeSelect) {
                <app-cascade-select [field]="field" [primary]="form.get('primary')" [secondary]="form.get('secondary')"> </app-cascade-select>
              }
              <!--EXP DATE + CVV-->
              @case (field.type === 'card_expiry_date') {
                <div class="inline-inputs">
                  <app-form-input
                    [control]="form.get('expiryMonth')"
                    [label]="Config['expiryMonth'] && Config['expiryMonth'].label | translate"
                  >
                    <input
                      class="input input--simple"
                      inputmode="decimal"
                      [type]="(Config['expiryMonth'] && Config['expiryMonth'].type) || 'text'"
                      mask="00"
                      [validation]="false"
                      [formControlName]="'expiryMonth'"
                      [name]="(Config['expiryMonth'] && Config['expiryMonth'].autocomplete) || 'expiryMonth'"
                      (focusout)="replaceMonthValue()"
                      (input)="handleAutoSwitch($event, 'expiryMonth', year, Config['expiryMonth']?.switchLength)"
                    />
                  </app-form-input>
                  <app-form-input [control]="form.get('expiryYear')" [label]="Config['expiryYear'] && Config['expiryYear'].label | translate">
                    <input
                      #year
                      class="input input--simple"
                      [type]="(Config['expiryYear'] && Config['expiryYear'].type) || 'text'"
                      mask="00"
                      [validation]="false"
                      inputmode="decimal"
                      [formControlName]="'expiryYear'"
                      [name]="(Config['expiryYear'] && Config['expiryYear'].autocomplete) || 'expiryYear'"
                      (focusout)="replaceYearValue()"
                      (input)="handleAutoSwitch($event, 'expiryYear', cvv, Config['expiryYear']?.switchLength)"
                    />
                  </app-form-input>
                  @if (!selectedSavedAccount && cvvField) {
                    <app-form-input [control]="form.get(cvvField.fieldName)" [label]="'CVV'">
                      <input
                        #cvv
                        class="input input--simple"
                        type="text"
                        mask="XXXX"
                        [hiddenInput]="true"
                        inputmode="decimal"
                        [validation]="false"
                        [formControlName]="cvvField.fieldName"
                      />
                    </app-form-input>
                  }
                </div>
              }
              <!--BOLETO PAYMENT NATIONAL ID FIELD-->
              @case (field.fieldName === 'nationalId') {
                <app-form-input
                  class="input-container"
                  [control]="form.get(field.fieldName)"
                  [label]="(Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
                >
                  <input
                    class="input input--simple"
                    [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                    [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                    [formControlName]="field.fieldName"
                    [placeholder]="field.placeholder || ''"
                    mask="000.000.000-00"
                    [validation]="false"
                  />
                </app-form-input>
              }
              @case (
                field.fieldName === 'clearingNumber' &&
                (method.brand === 'inpay' || method.brand === 'wire') &&
                method.type === 'bank_transfer' &&
                user.currentCurrency.currency === 'AUD'
                ) {
                <app-form-input
                  class="input-container"
                  [control]="form.get(field.fieldName)"
                  [label]="(Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
                >
                  <input
                    class="input input--simple"
                    [ngClass]="[field.fieldName]"
                    [type]="field.type ? field.type : (Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                    [formControlName]="field.fieldName"
                    [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                    [mask]="(Config[field.fieldName] && Config[field.fieldName].mask) || null"
                    InputNumber
                    maxlength="6"
                    [validation]="false"
                  />
                </app-form-input>
              }
              @case (
                field.fieldName === 'accountNumber' &&
                (method.brand === 'inpay' || method.brand === 'wire') &&
                method.type === 'bank_transfer' &&
                user.currentCurrency.currency === 'AUD'
                ) {
                <app-form-input
                  class="input-container"
                  [control]="form.get(field.fieldName)"
                  [label]="(Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
                  InputNumber
                >
                  <input
                    class="input input--simple"
                    [mask]="Config[field.fieldName]?.mask"
                    [validation]="false"
                    [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                    [formControlName]="field.fieldName"
                    [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                    [placeholder]="field.placeholder || ''"
                  />
                </app-form-input>
              }
              @default {
                @if (field.topDescription) {
                  <div class="field-description" [innerHTML]="field.topDescription | translate | safe: 'html'"></div>
                }
                @if (field.type !== 'cvv') {
                  <app-form-input
                    class="input-container"
                    [control]="form.get(field.fieldName)"
                    [label]="
                  field?.custom_label || (Config[field.fieldName] && Config[field.fieldName].label) || field.translationKey | translate
                "
                  >
                    @if (field.fieldName !== 'encCreditcardNumber') {
                      <input
                        class="input input--simple"
                        [mask]="Config[field.fieldName]?.mask"
                        [validation]="false"
                        [ngClass]="{ upper: Config[field?.fieldName]?.uppercase }"
                        [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                        [formControlName]="field.fieldName"
                        [maxlength]="field?.maxLength ? field.maxLength : null"
                        [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                        [placeholder]="field.hint && !field.bottomDescription && !field.topDescription ? field.hint : ''"
                      />
                    } @else {
                      <input
                        class="input input--simple card-input"
                        inputmode="decimal"
                        [mask]="Config[field.fieldName]?.mask"
                        [validation]="false"
                        [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                        [formControlName]="field.fieldName"
                        [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                        [placeholder]="field.placeholder || ''"
                      />
                    }
                  </app-form-input>
                }
                @if (field.bottomDescription) {
                  <div class="field-description bottom" [innerHTML]="field.bottomDescription | translate | safe: 'html'"></div>
                }
                @if (field.belowHint) {
                  <div class="field-description">{{ field.belowHint | translate }}</div>
                }
              }
            }
          }
        </div>
      </ng-template>
      <ng-template #cryptoFields>
        <div class="field-list">
          @for (field of fieldsToRender; track field) {
            @switch (true) {
              <!--BANNER-->
              @case (field.type === Form.MethodFieldType.Banner) {
                <div class="input-container html html-banner" [innerHTML]="field.htmlContent | safe: 'html'"></div>
              }
              <!-- MULTI PROTOCOLS -->
              @case (field.type === Form.MethodFieldType.MultiProtocolCryptoAddress) {
                <ng-container *ngTemplateOutlet="multiProtocolPartCryptoFields; context: { $implicit: field }"></ng-container>
              }
              <!-- MULTI PROTOCOLS -->
                <!-- CRYPTO ADDRESS-->
              @case (field.type === Form.MethodFieldType.DepositCryptoAddress && action === Payment.Action.Deposit) {
                <div>
                  @if (field?.fieldName === 'address') {
                    <div class="exchange">
                      @if (action === Payment.Action.Deposit) {
                        <app-exchange-rates [field]="field" [action]="action" [method]="method"></app-exchange-rates>
                      }
                    </div>
                  }
                  <app-deposit-crypto-address [field]="field"></app-deposit-crypto-address>
                </div>
              }
              <!--DEFAULT-->
              @default {
                <app-form-input
                  class="input-container"
                  [control]="form.get(field.fieldName)"
                  [label]="
                  field.custom_label || (Config[field.fieldName] && Config[field.fieldName].label) || field.translationKey | translate
                "
                >
                  <input
                    class="input input--simple"
                    [attr.data-field-name]="field.fieldName"
                    [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                    [formControlName]="field.fieldName"
                    [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                    [placeholder]="field?.hidePlaceholder ? '' : field.hint || ''"
                  />
                </app-form-input>
              }
              <!--DEFAULT-->
            }
          }
        </div>
      </ng-template>
    }

      @if (!payment.hidePlayerFieldsInPaymentForm && fieldsToRenderProfile?.length && payment.isSecondDepositStep) {
        <ng-container *ngTemplateOutlet="profileFieldsTemplate; context: {form}"></ng-container>
      }
  </div>
  @if (method?.savedProfiles?.length) {
    <div class="saved-accounts">
      <div class="saved-accounts__title">{{ 't.last-used' | translate }}</div>
      @for (savedMethod of method?.savedProfiles; track savedMethod) {
        <div
          class="saved-account"
          [ngClass]="{ active: savedMethod?.id === selectedSavedAccount?.id }"
          (click)="selectPaymentAccount(savedMethod)"
        >
          <img
            class="saved-account__img"
            defaultImage
            [alternative]="method.iconSrc"
            [attr.data-src]="method['ourImg']"
            [src]="method['ourImg']"
            [alt]="method.brand"
          />
          <span class="saved-account__identifier">{{ savedMethod?.title }}</span>
          @if (selectedSavedAccount && cvvField && savedMethod.id === selectedSavedAccount?.id) {
            <app-form-input class="saved-account__input" [formGroup]="form" [control]="form?.get(cvvField?.fieldName)">
              <input
                class="saved-cvv"
                type="text"
                maxlength="4"
                inputmode="decimal"
                mask="XXXX"
                [validation]="false"
                [hiddenInput]="true"
                [attr.data-field-name]="cvvField.fieldName"
                [formControlName]="cvvField?.fieldName"
                placeholder="CVV"
              />
            </app-form-input>
          }
        </div>
      }
      @if (selectedSavedAccount && action !== Payment.Action.Cashout) {
        <span class="add-account link">
          <span (click)="selectPaymentAccount(null)">{{
            (method?.type === 'cards' ? 't.add-new-card' : 'btn.create-new-acc') | translate
          }}</span>
        </span>
      }
    </div>
  }
} @else {
  <div class="preloading">
    <app-preloader></app-preloader>
  </div>
}

<ng-template #multiProtocolPartCryptoFields let-field>
  <div class="tabs">
    <div class="tab__title">{{ 't.select-your-protocol' | translate }}</div>
    <div class="tab">
      <app-tabs #tabsProtocol (select)="onSelectProtocol($event, field)">
        @for (protocol of field?.protocols; track protocol; let i = $index) {
          <app-tab-item #tabItem [tabTitle]="protocol">
            @if (tabItem.active) {
              <div class="field-list">
                <!-- CRYPTO ADDRESS-->
                @if (field.type === Form.MethodFieldType.MultiProtocolCryptoAddress) {
                  <div>
                    @if (action === Payment.Action.Deposit) {
                      <app-exchange-rates [field]="field" [action]="action" [method]="method"></app-exchange-rates>
                    }
                    @if (action === Payment.Action.Deposit) {
                      <app-deposit-crypto-address [field]="field.addressByProtocol[field?.protocols[i]]"> </app-deposit-crypto-address>
                    }
                  </div>
                }
                <!-- CRYPTO ADDRESS-->
                <!--BANNER-->
                @if (field?.bannersByProtocol) {
                  <div
                    class="input-container html html-crypto html-banner"
                    [innerHTML]="field?.bannersByProtocol[field.protocols[i]][0].htmlContent | safe: 'html'"
                  ></div>
                }
                <!--BANNER-->
              </div>
            }
          </app-tab-item>
        }
      </app-tabs>
    </div>
  </div>
</ng-template>


<ng-template #profileFieldsTemplate let-form="form">
  @if (!payment.hidePlayerFieldsInPaymentForm) {
    <div [formGroup]="form" class="field-list profile-fields">
      @for (field of fieldsToRenderProfile; track field) {
        @switch (true) {
          <!-- NAME -->
          @case (field.fieldName === 'first_name' || field.fieldName === 'last_name') {
            <app-form-input
              class="input-container"
              [control]="form.get(field.fieldName)"
              [label]="field.custom_label || (Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
            >
              <input
                class="input input--simple"
                [attr.data-field-name]="field.fieldName"
                [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                [formControlName]="field.fieldName"
                [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                [placeholder]="field?.hidePlaceholder ? '' : field.placeholder || ''"
              />
            </app-form-input>
          }
          <!-- NAME -->
            <!-- PHONE -->
          @case (field.type === 'phone') {
            <app-form-input class="input-container" [control]="form.get(field.fieldName)">
              <app-phone-form [formControlName]="field.fieldName"></app-phone-form>
            </app-form-input>
          }
          <!-- PHONE -->
            <!-- DATE -->
          @case (field.type === 'date') {
            <app-form-input class="input-container" [control]="form.get(field.fieldName)"
                            [label]="Config[field.fieldName].profile_label || (Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
            >
              <input
                class="input input--simple"
                type="text"
                name="date_of_birth"
                [formControlName]="field.fieldName"
                placeholder="DD/MM/YYYY"
                appDobMask
              />
            </app-form-input>
          }
          <!-- DATE -->
            <!--SELECT-->
          @case (field.type === FieldType.SELECT) {
            <app-form-input class="input-container" [control]="form.get(field.fieldName)" [attr.data-field-name]="field.fieldName">
              <app-select class="select" [formControlName]="field.fieldName">
                <app-select-header class="select__header">{{
                    (Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate
                  }}</app-select-header>
                @if (field?.fieldName !== 'country_code' && field?.fieldName !== 'countryCode') {
                  <app-select-dropdown class="select__dropdown">
                    @for (option of field?.options; track option) {
                      <app-select-option class="select__option" [value]="option.value">{{ option.label }}</app-select-option>
                    }
                  </app-select-dropdown>
                }
              </app-select>
            </app-form-input>
          }
          <!--SELECT-->
            <!--DEFAULT-->
          @default {
            <app-form-input
              class="input-container"
              [control]="form.get(field.fieldName)"
              [label]="Config[field.fieldName].profile_label || (Config[field.fieldName] && Config[field.fieldName].label) || field.fieldName | translate"
            >
              @if (field.fieldName === 'address') {
                <div class="suggestion-list" [class.visible]="payment.addressesSuggestion?.length > 0">
                  @for (suggestion of payment.addressesSuggestion; track suggestion) {
                    <div class="suggestion-list__item" (click)="payment.selectSuggestion(form, suggestion, 'address')">
                      <span>{{ suggestion?.formatted }}</span>
                    </div>
                  }
                </div>
              }
              @if (field.fieldName === 'city') {
                <div class="suggestion-list" [class.visible]="payment.citiesSuggestion.length > 0">
                  @for (city of payment.citiesSuggestion; track city) {
                    <div class="suggestion-list__item" (click)="payment.selectSuggestion(form, city, 'city')">{{ city?.city }}</div>
                  }
                </div>
              }
              @if (field.fieldName === 'postal_code') {
                <div class="suggestion-list" [class.visible]="payment.zipCodeSuggestion?.length > 0">
                  @for (suggestion of payment.zipCodeSuggestion; track suggestion) {
                    <div class="suggestion-list__item" (click)="payment.selectSuggestion(form, suggestion, 'postal_code')">
                      <span>{{ suggestion?.postcode }}</span>
                    </div>
                  }
                </div>
              }
              <input
                class="input input--simple"
                [attr.data-field-name]="field.fieldName"
                [type]="(Config[field.fieldName] && Config[field.fieldName].type) || 'text'"
                [formControlName]="field.fieldName"
                [name]="(Config[field.fieldName] && Config[field.fieldName].autocomplete) || field.fieldName"
                [placeholder]="field?.hidePlaceholder ? '' : field.hint || ''"
              />
            </app-form-input>
          }
          <!--DEFAULT-->
        }
      }
    </div>
  }
</ng-template>
