import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from './platform.service';
import { debounceTime, map } from 'rxjs/operators';
import { GlobalEventsService } from './global-events.service';
import { UntilDestroy } from 'ngx-unificator/decorator';

export interface WindowSize {
  width: number | any;
  height: number | any;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})

export class WindowSizeService {

  public readonly newWindowSize = new BehaviorSubject<WindowSize>({
    width: this._platform.isBrowser ? (window as any).innerWidth : null,
    height: this._platform.isBrowser ? (window as any).innerHeight : null
  } as WindowSize);

  constructor(
    private _platform: PlatformService,
    private _events: GlobalEventsService
  ) {
    if (this._platform.isBrowser) {
      this._events.resize$.pipe(
        debounceTime(100),
        map(event => <WindowSize> {
          width: (<any>window).innerWidth,
          height: (<any>window).innerHeight
        })
      ).subscribe((windowSize) => this.newWindowSize.next(windowSize));
    }
  }
}
