import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { catchError, delay, filter, first, map, share, switchMap, tap } from 'rxjs/operators';
import { CommonDataService, CRYPTO_ACCOUNTS, isCryptoAcc, NOT_NEEDED_CURRENCIES } from '../common-data.service';
import { UserInfoService } from './user-info.service';
import { CmsContentMapperService } from '../cms-content-mapper.service';
import { CmsApiService } from '../api/cms-api.service';
import { CookieService } from 'ngx-unificator/services';
import { Router } from '@angular/router';
import { PlatformService } from '../platform.service';
import { EnvironmentService } from '../environment.service';
import { LoyaltyGroups } from '../loyalty/loyalty-data';
import { LocalstorageService } from '../localstorage.service';
import { BannerType } from '../../../../environments/environment';
import { BannersService } from '../banners.service';
import { GoogleTagManagerService } from '../google-tag-manager.service';
import { ModalService } from '../../modal-v2/modal.service';
import { CacheControlService } from '../cache-control.service';
import { DecimalPipe } from '@angular/common';
import { CurrencyFormatPipe } from '../../shared/pipes/currency-format.pipe';

export const CRYPTO_GROUP = 'btcplayer';
export const FIRST_TIME_DEP = 'ID402';

export const FIRST_DEP = 'ID402';
export const SECOND_DEP = 'ID572';

export const FIRST_CASHOUT = 'ID638';

export enum UserSides {
  SAMURAI = 'samurai',
  NINJA = 'ninja',
  NOT_CHOOSEN = 'not_choosen',
}

export enum CURRENCY_EQUIVALENT{
  EUR = 1,
  USD = 1,
  NOK = 10,
  AUD = 1,
  CAD = 1,
  PHP = 60,
  BRL = 5,
  ZAR = 20,
  JPY = 160,
  PLN = 4,
  KRW = 1400,
}


export const STORAGE_LESS_MODAL_SHOWED = '--less-modal-was-shown';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Emits when user auth status ready
   */
  private _auth$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Emits when user currency changed
   */
  private _currency$: ReplaySubject<string> = new ReplaySubject<string>(1);

  /**
   * Is user authorized
   */
  private _auth: boolean;

  /**
   * Player's additional data
   */
  private _playerStats: any = {};

  /**
   * Player's wins
   */
  private _playerWins: any = null;

  /**
   * User info
   */
  private _info: any = {};

  /**
   * User`s active currency (account)
   */
  private _currentCurrency: any;

  private _currentCurrencyCompatibility: any;

  /**
   * List of user accounts (currencies)
   */
  private _accountList: Array<any> = [];

  /**
   * Emits list of user accounts (currencies)
   */
  private _accountList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _accountListCompatibility: Array<any> = [];

  private _currentCurrencyCompatibility$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _timeoutPlayerLongRequest: any;

  private _isUserAlreadyRegistered: boolean;

  private _loyaltyActiveLevel: string;

  private _isAlreadyChanged = false;

  private _decimalPipe = new DecimalPipe('en');
  private _currency = new CurrencyFormatPipe(this._decimalPipe);

  constructor(
    private _ssApi: SsApiService,
    private _cmsApi: CmsApiService,
    private _commonData: CommonDataService,
    private _userInfo: UserInfoService,
    private _contentMapper: CmsContentMapperService,
    private _cookies: CookieService,
    private _router: Router,
    private _platform: PlatformService,
    private _env: EnvironmentService,
    private _storage: LocalstorageService,
    private _banners: BannersService,
    private _gtm: GoogleTagManagerService,
    private _modal: ModalService,
    private _cache: CacheControlService
  ) {
    /**
     * For avoiding circular dependency
     */
    this._userInfo.userService = this;
    this._contentMapper.user = this;

    this.fetchAllUserData();
    this._checkUserAlreadyRegistered();
  }

  /**
   * Access to private fields from outside
   */
  get auth(): boolean { return this._auth; }
  get auth$(): ReplaySubject<boolean> { return this._auth$; }
  get info(): any { return this._info; }
  get currentCurrency(): any { return this._currentCurrency; }
  get currentCurrencyCompatibility(): any { return this._currentCurrencyCompatibility; }
  get currency$(): ReplaySubject<string> { return this._currency$; }
  get accountList(): Array<any> { return this._accountList; }
  get accountList$(): ReplaySubject<any> { return this._accountList$; }
  get accountListCompatibility(): Array<any> { return this._accountListCompatibility; }
  get stats(): Array<any> { return this._playerStats; }
  get hasDeposit(): boolean { return !!this._playerStats.deposits_count; }
  get wins(): any {
    return this._playerWins;
  }

  get currentCurrencyToEquivalent(): any {
    return CURRENCY_EQUIVALENT[this.currentCurrency?.currency];
  }


  get currentCurrencyCompatibility$(): ReplaySubject<any> { return this._currentCurrencyCompatibility$; }

  get loyaltyActiveLevel() { return this._loyaltyActiveLevel; }
  /**
   * Access to __checkUserAlreadyRegistered
   */
  get isUserAlreadyRegistered(): boolean {
    return this._isUserAlreadyRegistered;
  }


  /**
   * Get user account list and save to local var
   */
  getUserAccounts(): Observable<any> {
    return this._ssApi.playerAccounts().pipe(
      map(accountList => accountList.filter(account => !NOT_NEEDED_CURRENCIES.includes(account.currency))),
      map(accountList => this._serializeCurrencyValues(accountList)),
      catchError(() => of([])),
      tap(accountList => {
        this._accountList = accountList;
        this._accountList$.next(accountList);
        this._currentCurrency = this._accountList.find(account => account.currency === this._info.currency) || {};
        this._currency$.next(this._currentCurrency.currency);
      })
    );
  }

  /**
   * Get user account compatibility list and save to local var
   */
  getUserAccountsCompatibility(): Observable<any> {
    return this._ssApi.playerAccounts({compatibility: false}).pipe(
      map(accountList => accountList.filter(account => !NOT_NEEDED_CURRENCIES.includes(account.currency))),
      map(accountList => this._serializeCurrencyValues(accountList)),
      map(accountList => accountList.filter(account => this.isHasCryptoAccounts() ?
        isCryptoAcc(account.currency) : accountList)),
      catchError(() => of([])),
      tap(accountList => {
        this._accountListCompatibility = accountList;
        this._currentCurrencyCompatibility = this._accountListCompatibility.find(account => account.currency === this._info.currency) || {};
        this._currentCurrencyCompatibility$.next(this._currentCurrencyCompatibility);
      })
    );
  }

  /**
   * Get user info and save to local var
   */
  getUserInfo(): Observable<any> {
    this.waitingForPlayerRequest();
    return this._ssApi.player().pipe(
      catchError(() => of({})),
      tap(info => {
        this.applyUserInfo(info);
      }),
    );
  }

  /**
   * Get additional player`s data from backend
   */
  getPlayerStats(): Observable<any> {
    return this._ssApi.playerStats().pipe(
      catchError(() => of({})),
      tap(stats => {
        this._playerStats = stats;
      })
    );
  }

  /**
   * Get player`s wins from backend
   */
  getPlayerWins(data: {id: string, currency: string}): Observable<any> {
    return this._cmsApi.playerWins({
      player_id: data.id,
      currency: data.currency,
    }).pipe(
      filter(data => !!data),
      map(data  => data?.data?.data?.list),
      tap(wins => {
        if (wins) {
          this._playerWins = Object.entries(wins[0] || {}).reduce((acc, [key, value]) => {
            acc[key] = this._currency.transform(+value, data.currency);
            return acc;
          }, {})
        }
      })
    );
  }

  /**
   * Save user info to local var and declare user state
   *
   * @param info
   */
  applyUserInfo(info: any) {
    const userSide = info && info.statuses
      ? info.statuses.find((e) => e.id.toLowerCase() === UserSides.SAMURAI || e.id.toLowerCase() === UserSides.NINJA)
      : '';

    this._info = {
      ...info,
      gender_name: info.gender === 'm' ? 'Male' : info.gender === 'f' ? 'Female' : '',
      country_name: this._commonData.countryName(info.country || ''),
      userSide: userSide && userSide.id ? userSide.id.toLowerCase() : '',
      currency_symbol: this._commonData.currencySymbol(info?.currency)
    };

    if (info.statuses && info.statuses.length) {
      const uGroup = {
        key: 'U-GROUP',
        val: info.statuses.map(status => status.id).join(',')
      }

      this._cmsApi.customHeadersList.push(uGroup);
      this._cmsApi.customNoLangHeadersList.push(uGroup);

      const loyaltyActiveLevel = Object.values(LoyaltyGroups).reverse().find(level => {
        return info.statuses.find(status => status.id === level);
      });

      if (loyaltyActiveLevel) {
        this._loyaltyActiveLevel = loyaltyActiveLevel;
      }
    }

    if ('id' in info) {
      this._cmsApi.customHeadersList.push({key: 'UID', val: `${info.id}`});
      this._cookies.set('UID', info.id);
      if (info.country) {
        this._cmsApi.customHeadersList.push({key: 'UC', val: info.country});
        this._cmsApi.customNoLangHeadersList.push({key: 'UC', val: info.country});
      }
      this._updateCountryIfEmpty(info);
      this._auth = true;
      this.auth$.next(true);
      this._applyGoogleRegCurrency();
      this._setAlreadyRegisteredCookie();
      this._userInfo.checkMissingAuthFields(info);
    } else {
      this._auth = false;
      this.auth$.next(false);
    }
  }

  private _serializeCurrencyValues(accountList: any[]) {
    return accountList.map(account => {
      return {
        ...account,
        amount: this._commonData.subunitsToUnits(account.amount_cents, account.currency),
        cashout: this._commonData.subunitsToUnits(account.available_to_cashout_cents, account.currency),
        symbol: this._commonData.currencySymbol(account.currency),
        isCrypto: this._commonData.cryptoCurrencyList.map(e => e.code).includes(account.currency),
      };
    });
  }

  private _setAlreadyRegisteredCookie() {
    if (!this._isUserAlreadyRegistered) {
      this._cookies.set('registered', '1', 999, '/', (window.location.hostname as any));
    }
  }

  /**
   * Fetch all required user data
   *
   * @private
   */
  public fetchAllUserData() {
    const observable = this._commonData.loaded$.pipe(
      first(),
      switchMap(() => this.getUserInfo()),
      switchMap(() => this.getPlayerStats()),
      switchMap(() => this.getUserAccounts()),
      switchMap(() => this.getUserAccountsCompatibility()),
      share()
    );

    observable.subscribe();

    return observable;
  }

  /**
   * Update current currency balance info
   *
   * @private
   */
  private _updateCurrentCurrencyBalance() {
    this._currentCurrency = this._accountList.find(account => account.currency === this._info.currency) || {};
    this._currency$.next(this._currentCurrency.currency);
  }

  /**
   * Change user currency account
   * @param selectedCurrency
   */
  public changeCurrencyAcc(selectedCurrency: string) {
    if (selectedCurrency) {
      this._currency$.next(null);
      this._ssApi.postPlayerAccounts({currency: selectedCurrency}).subscribe((e) => {
        this.fetchAllUserData();
      });
    }
  }

  /**
   * Check is user already registered
   */
  private _checkUserAlreadyRegistered() {
    this._isUserAlreadyRegistered = this._cookies.check('registered');
  }

  public isHasCryptoAccounts() {
    if (this.info && this.info.statuses) {
      return this.info.statuses.some(status => status.id === CRYPTO_GROUP);
    }
  }

  public get isCryptoAccountSelected(): boolean {
    return CRYPTO_ACCOUNTS.some(acc => acc.toUpperCase() === this.currentCurrency.currency.toUpperCase())
  }

  /**
   * Waiting for /player response 2 seconds and emit default value
   * @private
   */
  private waitingForPlayerRequest() {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        if (this._auth === undefined) {
          this.applyUserInfo({language: 'en'});
        }
      }, 2000);
    }
  }

  /**
   * Check if user allready registered and redirect
   */
  public authUser() {
    return this._router.navigateByUrl(this._cookies.check('registered') ? '/login' : '/register');
  }

  /**
   * Check if user allready registered and return dymanic translate key
   */
  public get authKeyTranslate(): string {
    return this.isUserAlreadyRegistered ? 'btn.login' : 'btn.signup';
  }

  /**
   * Update the country if it was not set
   * @private
   * @param info
   */
  private _updateCountryIfEmpty(info) {
    if (!info.country) {
      this._userInfo.updatePlayerInfo({country: this._env.env?.country?.short?.toUpperCase()}, 'edition').pipe(
        switchMap(() => this.getUserInfo()),
        catchError(err => of(err))
      ).subscribe();
    }
  }

  /**
   * Update user currency when he register first time
   * @private
   */
  private _applyGoogleRegCurrency() {
    if (!this._isUserAlreadyRegistered && !this._isAlreadyChanged) {
      this._isAlreadyChanged = true;
      this.changeCurrencyAcc(this._env.env.currency.short);
    }
  }

  /**
   * Show less balance modal
   */
  public showLessBalanceModal() {
    return this._banners.item({
      type: BannerType.LOW_BALANCE,
      alias: 'low-balance'
    }).pipe(
      delay(2000),
      filter(data => !!data && !Boolean(this._storage.get(STORAGE_LESS_MODAL_SHOWED))),
      map(data => data?.[0]),
      filter(data => !! data),
      first(),
      tap( async (lowBalanceData) => {
        if (this.currentCurrency.amount < lowBalanceData.balance &&
          this.currentCurrency.amount > this.currentCurrencyToEquivalent) {
          this._gtm.lowBalancePopupStart('low_balance_popup', this.info.id);
          const component = await import('../../modal-v2/components/lazy/low-balance-modal/low-balance-modal.component');
          await this._modal.openLazy(component?.LowBalanceModalComponent, {
            data: lowBalanceData,
            template: 'CLEAR'
          });
          this._storage.set(STORAGE_LESS_MODAL_SHOWED, '1');
        }
      })
    ).subscribe();
  }
}
